import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import styled from 'styled-components'

const Paincrumb = styled(Breadcrumb)`
  // cancel container margins
  margin: -1.5rem -1.5rem 0 -1.5rem;
  // override default Breadcrumb styles
  .breadcrumb {
    padding: 0.52rem 1rem 0.26rem;
    margin-bottom: 2rem;
    background-color: ${process.env.FLAVOR === '23hbd' ? "#f5f3f2" : "#efefef"};
    color: #9c9d9e;
    border-bottom: solid 1px #ececec;
    border-radius: 0;
    font-size: 0.83rem;
  }
  .breadcrumb-item a {
    color: inherit;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: inherit;
    content: "»";
  }
`

Paincrumb.Item = BreadcrumbItem

export default Paincrumb
