import { Query } from "@apollo/react-components"
import gql from "graphql-tag"
import React from "react"
import { Featured } from "./Featured"

export function RandomRead({ year }) {
  return (
    <div className="d-none d-md-block">
      <div className="mt-4 pl-5">
        <strong>{t(`global.3-random-comics`)}</strong>
      </div>
      <Query
        query={gql`
          query RandomParticipations($editionYear: Int) {
            randomParticipations(fromEdition: { year: $editionYear }, fullOnly: true, count: 3) {
              id
              edition {
                id
                year
              }
              title
              user {
                id
                slug
                username
              }
              specificUsername
              firstPage {
                image {
                  url
                }
              }
            }
          }
        `}
        variables={{
          editionYear: year,
        }}
        fetchPolicy="no-cache"
      >
        {({ data }) => {
          if (!data || !data.randomParticipations) return null
          // return <pre>{JSON.stringify(data, null, 2)}</pre>
          return (
            <Featured>
              {data.randomParticipations.map(
                ({
                  id,
                  title,
                  user: { username, slug },
                  firstPage: {
                    image: { url: coverUrl },
                  },
                }) => (
                  <Featured.Item
                    key={id}
                    title={title || t(`global.by-author`, { author: username })}
                    imgSrc={coverUrl}
                    links={[
                      <Featured.Item.Link
                        key={0}
                        style={{ fontSize: "0.9em" }}
                        to={`/participants/${year}/${slug}`}
                      >
                        {t(`global.read`)}
                      </Featured.Item.Link>,
                    ]}
                  />
                )
              )}
            </Featured>
          )
        }}
      </Query>
    </div>
  )
}
