import React, { useContext } from "react"
import Profile from "./Profile"
import { Button, ButtonGroup } from "reactstrap"
import { Route } from "react-router"
import Planches from "./Planches"
import Comments from "./Comments"
import compose from "utils/compose"
import { ME } from "gql/queries"
import { Redirect } from "react-router"
import { Query } from "@apollo/react-components"
import RelevantEditionContext, {
  Provider as RelevantEditionProvider,
} from "./RelevantEditionContext"
import "./account.scss"
import useRouter from "utils/useRouter"
import cn from "classnames"

function Account({ userId }) {
  const { relevantEdition } = useContext(RelevantEditionContext)
  const participates = relevantEdition && relevantEdition.myParticipation
  return pug`
    #account-page
      if participates
        nav.nav-top.align-items-center.d-flex
          ButtonGroup
            NavButton.btn.px-4(to="/compte/", exact)= t('account-page.menu.profile')
            NavButton.btn.px-4(to="/compte/planches/")= t('account-page.menu.planches')
            NavButton.btn.px-4(to="/compte/commentaires")= t('account-page.menu.comments')
      Route(
        exact
        path="/compte/"
        render=${(props) => pug`
          Profile(...props userId=userId)
        `}
      )
      if participates
        Route(exact path="/compte/planches/" render=${(props) => (
          <Planches {...props} userId={userId} />
        )})
        Route(exact path="/compte/commentaires/" render=${(props) =>
          pug`Comments(...props userId=userId)`})
  `
}

export function NavButton({
  to,
  exact,
  replace = false,
  activeClassName = process.env.FLAVOR === '23hbd' ? "btn-light" : "btn-primary",
  inactiveClassName = process.env.FLAVOR === '23hbd' ? "btn-completion-filter" : "btn-light",
  href = to,
  ...props
}) {
  const { match, navigate } = useRouter({
    path: to,
    exact: !!exact,
  })

  return (
    <Button
      onClick={(e) => {
        e.preventDefault()
        navigate(to, { replace })
      }}
      {...props}
      href={href}
      className={cn(props.className, match ? activeClassName : inactiveClassName)}
      color=""
    />
  )
}

const withRelevantEditionContext = (ChildComponent) => (props) => (
  <RelevantEditionProvider>
    <ChildComponent {...props} />
  </RelevantEditionProvider>
)

const withUserId = (ChildComponent) => (props) => (
  <Query query={ME}>
    {({ data, error, loading }) =>
      error || loading || !data || !data.me ? (
        <Redirect to="/" />
      ) : (
        <ChildComponent {...props} userId={data.me.id} />
      )
    }
  </Query>
)

const enhance = compose(withUserId, withRelevantEditionContext)

export default enhance(Account)
