import React, { useEffect, useMemo } from "react"
import Well, { WellTitle } from "components/Well"
import { Row, Col } from "reactstrap"
import Author from "../Author"
import withQueryResult from "utils/withQueryResult"
import Comments from "../Comments"
import Achievement from "components/Achievement"
import ParticipationContext from "../ParticipationContext"
import moment from "moment"
import Reactions from "../Reactions"
import useRouter from "utils/useRouter"
import LightboxViewer from "./LightboxViewer"
import TwitchWidgetMaybe from "./TwitchWidgetMaybe"
import styled from "styled-components"
import qs from "query-string"
import WebtoonReader from "../WebtoonReader"
import { Link } from "react-router-dom"
import { ParticipationDesktopPage } from "../operations.gql"
import Paincrumb from "components/common/Paincrumb"
import cn from "classnames"
import { useIsNSFWHidden } from "../useIsNSFWHidden"
import NSFWDisclaimer from "../NSFWDisclaimer"

const Participation = ({ participation }) => {
  const { history, location } = useRouter()
  const openPage = (pageIndex) => {
    history.replace({ search: `?page=${pageIndex + 1}` })
  }

  const customParticipationIframe =
    participation.customData && participation.customData.participationIframe
  const now = useMemo(() => moment(), [])
  const editionHasNotStartedYet = now.isBefore(moment(participation.edition.beginDate))
  const readerIsOpen = !!qs.parse(location.search).page
  const isNSFWHidden = useIsNSFWHidden(participation)

  return (
    <ParticipationContext.Provider value={{ participation }}>
      <Paincrumb>
        <Paincrumb.Item>
          <Link to={`/participants/${participation.edition.year}/`}>
            {t(`participation-page.breadcrumb.participations-page`, {
              year: participation.edition.year,
            })}
          </Link>
        </Paincrumb.Item>
        <Paincrumb.Item>
          <a href="">
            {t(`participation-page.breadcrumb.participation-page`, {
              username: participation.specificUsername || participation.user.username,
            })}
          </a>
        </Paincrumb.Item>
      </Paincrumb>
      <div>
        <Row>
          <Col lg={8}>
            {participation.disclaimer && (
              <Well alt>
                {/* <WellTitle>Notes de l'auteur</WellTitle> */}
                <div
                  style={{ fontSize: "1rem" }}
                  dangerouslySetInnerHTML={{ __html: participation.disclaimer }}
                />
              </Well>
            )}
            {!!customParticipationIframe && (
              <Well alt>
                <div
                  className="d-flex align-items-center justify-content-center"
                  dangerouslySetInnerHTML={{
                    __html: customParticipationIframe,
                  }}
                />
              </Well>
            )}
            {(!customParticipationIframe || !!participation.pages.length) && (
              <Well alt className="mb-0">
                <WellTitle>
                  <div className="d-flex align-items-center">
                    <Title>
                      {(participation.edition.useTitles && participation.title) ||
                        t(`participation-page.planches.title`)}
                    </Title>
                    <PagesCount>
                      {`${participation.pagesDone}/${participation.pagesGoal}`}
                      {participation.achievement && (
                        <Achievement style={{ left: 4 }} yFix altSilver className="ml-2">
                          {participation.achievement}
                        </Achievement>
                      )}
                    </PagesCount>
                  </div>
                </WellTitle>
                {isNSFWHidden && <NSFWDisclaimer className="my-2" />}
                {participation.pages.length ? (
                  <div className="d-flex flex-wrap">
                    {participation.pages.map((page, i) => (
                      <Planche
                        key={i}
                        page={page}
                        pageNumber={i + 1}
                        openPage={openPage.bind(null, i)}
                        hiddenNSFW={isNSFWHidden && i > 0}
                      />
                    ))}
                    {readerIsOpen && <RoutedReader participation={participation} />}
                  </div>
                ) : (
                  <div className="text-center text-muted-light py-3" style={{ fontSize: "1.2em" }}>
                    {t(`participation-page.empty-desktop`)}
                  </div>
                )}
              </Well>
            )}

            <TwitchWidgetMaybe participation={participation} />

            {participation.allowReactions && !editionHasNotStartedYet && (
              <Reactions participationId={participation.id} className="mt-2 p-3 mb-2" />
            )}
          </Col>

          <Col lg={4}>
            <Well title={t(`participation-page.author.title`)} alt>
              <Author userId={participation.user.id} />
            </Well>
          </Col>
        </Row>

        {participation.allowComments && (
          <Comments
            participationId={participation.id}
            style={{ borderTop: "solid 1px #ccc" }}
            className="pt-4 mt-5"
          />
        )}
      </div>
    </ParticipationContext.Provider>
  )
}

const Title = styled.div`
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`

function RoutedReader({ participation }) {
  const { location, history } = useRouter()
  const pageNumberStrFromUrl = qs.parse(location.search).page
  const currentPageNumber = parseInt(pageNumberStrFromUrl)
  return (
    <DesktopReader
      participation={participation}
      currentPage={currentPageNumber}
      setCurrentPage={(pageNumber) => {
        history.replace({ search: `?page=${pageNumber}` })
      }}
      onClose={() => {
        history.replace({ search: "" })
      }}
    />
  )
}

export function DesktopReader(props) {
  useEffect(() => {
    document.body.classList.add("reading")
    return () => {
      document.body.classList.remove("reading")
    }
  }, [])
  if (props.participation.challengeType === "webtoon") return <WebtoonReader {...props} />
  return <LightboxViewer {...props} />
}

const PagesCount = styled.div`
  height: 1.9rem;
  line-height: 1.9rem;
  border-radius: 0.95rem;
  background-color: #999;
  margin-left: 0.5em;
  font-size: 1rem;
  color: #eee;
  text-shadow: -1px -1px #777;
  padding: 0 1.2em;
  margin-top: 6px;
`

const Planche = ({ page, openPage, pageNumber, hiddenNSFW = false, ...otherProps }) => {
  const dimensions = {
    width: Math.round((page.width / page.height) * 125),
    height: 125,
  }
  return (
    <div
      className={cn("account-planche", { "account-planche-hidden-nsfw": hiddenNSFW })}
      {...otherProps}
    >
      <a
        target="blank"
        href={hiddenNSFW ? undefined : page.url}
        onClick={(e) => {
          e.preventDefault()
          if (!hiddenNSFW) openPage()
        }}
        className="img-container"
        style={dimensions}
      >
        <img
          src={page.thumbnail.url}
          alt={`Page ${pageNumber}`}
          // className="animated"
          style={{
            ...dimensions,
            animationDelay: `${(pageNumber - 1) * 0.02}s`,
          }}
        />
      </a>
    </div>
  )
}

const withParticipation = withQueryResult(ParticipationDesktopPage, {
  variables: ({ participationId }) => ({ participationId }),
})

const DesktopParticipation = withParticipation(Participation)

export default DesktopParticipation
