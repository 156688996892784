import EditIcon from "-!react-svg-loader!assets/images/edit.svg"
import { useMutation } from "@apollo/react-hooks"
import cn from "classnames"
import isIn from "common/fp/isIn"
import Well from "components/Well"
import emojiRegex from "emoji-regex"
import gql from "graphql-tag"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { toast } from "react-toastify"
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import styled from "styled-components"
import compose from "utils/compose"
import withMutation from "utils/withMutation"
import withQueryResult from "utils/withQueryResult"
import Achievement from "../../../Achievement"
import RelevantEditionContext from "../RelevantEditionContext"
import ChallengeTypeForm from "./ChallengeTypeForm"
import PageDragAndDropContainer from "./PageDragAndDropContainer"
import PlancheUploadForm from "./PlancheUploadForm"
import ParticipationDesktopQuickOpen from "components/pages/Participations/EditionParticipations/ParticipationDesktopQuickOpen"
import useRouter from "utils/useRouter"
import qs from "qs"

const Planches = ({ pages, movePage, participation, participationId, deletePage, edition }) => {
  const [reorderMode, setReorderMode] = useState(false)
  const now = moment()
  const [editChallengeType, setEditChallengeType] = useState(
    !participation.hasValidatedInitialChallengeType
  )
  const [showCannotChangeChallengeTypeModal, setShowCannotChangeChallengeTypeModal] =
    useState(false)
  const { location } = useRouter()
  const { quick_open: quickOpen } = qs.parse(location.search)

  const [updateParticipationTags] = useMutation(gql`
    mutation UpdateParticipationTags($participationId: ID!, $tags: [String!]!) {
      updateParticipation(participationId: $participationId, tags: $tags) {
        id
        tags
      }
    }
  `)

  if (editChallengeType) {
    return (
      <ChallengeTypeForm
        participationId={participationId}
        initialChallengeType={participation.challengeType}
        initialChoice={!participation.hasValidatedInitialChallengeType}
        onClose={() => {
          setEditChallengeType(false)
        }}
      />
    )
  }

  const updateNsfw = async (hasNsfw) => {
    if (hasNsfw && participation.tags.includes("nsfw")) {
      throw new Error(`Tags should not already include nsfw`)
    }
    const newTags = hasNsfw
      ? [...participation.tags, "nsfw"]
      : participation.tags.filter((tag) => tag !== "nsfw")
    await updateParticipationTags({
      variables: {
        participationId,
        tags: newTags,
      },
    })
    toast.success(t(`account-page.planches-page.content-guidelines.saved`))
  }

  return (
    <>
      <div style={{ marginTop: -10 }} className="pb-2 border-bottom">
        <Button
          color="light"
          className="btn-transparent-to-light"
          onClick={() => {
            if (pages.length === 0) {
              setEditChallengeType(true)
            } else {
              setShowCannotChangeChallengeTypeModal(true)
            }
          }}
        >
          {t(`account-page.planches-page.current-challenge-type`)}{" "}
          <span className="text-uppercase">
            {t(`global.challenge-type.${participation.challengeType}`)}
          </span>
          <EditIcon style={{ width: "1em", height: "1em" }} className="ml-3" />
        </Button>
        <Modal
          isOpen={showCannotChangeChallengeTypeModal}
          centered
          toggle={() => {
            setShowCannotChangeChallengeTypeModal(false)
          }}
        >
          <ModalHeader
            toggle={() => {
              setShowCannotChangeChallengeTypeModal(false)
            }}
          >
            {t(`account-page.planches-page.challenge-type.need-empty-modal.title`)}
          </ModalHeader>
          <ModalBody>
            <div>{t(`account-page.planches-page.challenge-type.need-empty-modal.text`)}</div>
          </ModalBody>
        </Modal>
      </div>

      <PageDragAndDropContainer disabled={!edition.status.usersCanUpload}>
        {edition.status.usersCanUpload ? (
          <React.Fragment>
            {now.isAfter(moment(edition.endDate)) &&
              (participation.achievement |> isIn(["gold", "pink"])) && (
                <div className="post-it-info mb-4 mx-5">
                  <Trans i18nKey="account-page.planches-page.lose-achievement-warning">
                    <strong>Attention !</strong> Le temps est fini, si tu fais des modifications
                    maintenant, tu perdras{" "}
                    {{
                      yourAchievement: t(
                        `flavored:global.your-${participation.achievement}-achievement`
                      ),
                    }}{" "}
                    !
                  </Trans>
                </div>
              )}
            <PlancheUploadForm participationId={participationId} />
          </React.Fragment>
        ) : (
          <div className="bg-grey h5 mx-5 p-4 text-center">
            {now.isAfter(moment(edition.endDate))
              ? t(`account-page.planches-page.upload-not-possible-anymore`)
              : t(`account-page.planches-page.upload-not-possible-yet`)}
          </div>
        )}

        {!!pages.length && (
          <Well>
            <Row>
              {edition.useTitles && pages.length !== 0 && (
                <Col lg={8}>
                  <TitleEdit participationId={participationId} title={participation.title} />
                </Col>
              )}
              <Col lg={4} className="pl-lg-4 mb-4 mb-lg-0">
                <Label className="d-block mb-2">
                  {t(`account-page.planches-page.content-guidelines.title`)}
                </Label>
                <FormGroup check>
                  <Input
                    type="checkbox"
                    checked={participation.tags.includes("nsfw")}
                    onChange={(e) => {
                      updateNsfw(e.target.checked)
                    }}
                  />{" "}
                  {t(`account-page.planches-page.content-guidelines.nsfw`)}
                </FormGroup>
              </Col>
            </Row>
            {!!quickOpen && <ParticipationDesktopQuickOpen participationId={participationId} />}
            <PlanchesList
              pages={pages}
              axis="xy"
              distance={3}
              onSortEnd={({ oldIndex, newIndex }) => {
                if (oldIndex != newIndex) movePage(oldIndex, newIndex)
              }}
              deletePage={deletePage}
              reorderEnabled={reorderMode}
            />
            <div
              className="d-flex align-items-center mt-3"
              style={{
                borderTop: "solid 1px rgba(0,0,0,0.1)",
                marginBottom: -8,
                paddingTop: 12,
              }}
            >
              <div className="flex-grow-1">
                {pages.length >= 2 && (
                  <React.Fragment>
                    {reorderMode ? (
                      <Button color="primary" size="sm" onClick={() => setReorderMode(false)}>
                        {t(`account-page.planches-page.reorder-mode.end`)}
                      </Button>
                    ) : (
                      <Button color="hbd" size="sm" onClick={() => setReorderMode(true)}>
                        {t(`account-page.planches-page.reorder-mode.start`)}
                      </Button>
                    )}
                    <ReorderInstructions
                      className={cn("ml-3 d-none d-md-inline", { show: reorderMode })}
                    >
                      {t(`account-page.planches-page.reorder-mode.instructions`)}
                    </ReorderInstructions>
                  </React.Fragment>
                )}
              </div>
              <div>
                <span style={{ whiteSpace: "nowrap" }}>
                  {participation.pagesDone} / {participation.pagesGoal}
                </span>
                <Achievement achievement={participation.achievement} className="ml-1" yFix />
              </div>
            </div>
          </Well>
        )}
        <div style={{ fontSize: 13 }}>
          {t(`account-page.planches-page.link-to-my-participation`)}{" "}
          <Link
            to={`/participants/${edition.year}/${participation.user.slug}/`}
            className="link-alt link-underlined"
          >
            {`${process.env.HTTP_HOST}/participants/${edition.year}/${participation.user.slug}/`}
          </Link>
        </div>
      </PageDragAndDropContainer>
    </>
  )
}

function TitleEdit({ participationId, title: baseTitle }) {
  const [title, setTitle] = useState(baseTitle || "")
  useEffect(() => {
    setTitle(baseTitle || "")
  }, [baseTitle || ""])
  const [updateTitle] = useMutation(
    gql`
      mutation UpdateParticipationTitle($participationId: ID!, $title: String) {
        updateParticipation(participationId: $participationId, title: $title) {
          id
          title
        }
      }
    `,
    {
      variables: {
        participationId,
        title: title || null,
      },
      onCompleted: () => {
        toast.success(t(`account-page.planches-page.title-edit.success`))
      },
    }
  )
  const lengthError = title.length > 100
  const emojiError = !!title.match(emojiRegex())
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        updateTitle()
      }}
    >
      <FormGroup>
        <Label>{t(`flavored:account-page.planches-page.title-edit.label`)}</Label>
        <div className="d-flex align-items-start">
          <div className="flex-grow-1">
            <Input
              type="text"
              name="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              invalid={lengthError || emojiError}
            />
            {lengthError && (
              <FormFeedback>
                {t(`account-page.planches-page.title-edit.errors.max-characters`, { max: 100 })}
              </FormFeedback>
            )}
            {!lengthError && emojiError && (
              <FormFeedback>
                {t(`account-page.planches-page.title-edit.errors.emojis`)}
              </FormFeedback>
            )}
          </div>
          {title !== (baseTitle || "") && (
            <>
              <Button
                color="primary"
                type="submit"
                className="ml-3"
                disabled={lengthError || emojiError}
              >
                {t(`account-page.planches-page.title-edit.save`)}
              </Button>
              <Button
                color="default"
                onClick={() => {
                  setTitle(baseTitle || "")
                }}
                className="ml-1"
              >
                {t(`account-page.planches-page.title-edit.cancel`)}
              </Button>
            </>
          )}
        </div>
      </FormGroup>
    </Form>
  )
}

const ReorderInstructions = styled.span`
  opacity: 0;
  transition: opacity 0.15s linear;
  &.show {
    opacity: 1;
  }
`

const PlanchesList = SortableContainer(({ pages, deletePage, reorderEnabled }) => (
  <div className="account-pages-list">
    {pages.map((page, i) => (
      <Planche
        key={i + 1}
        {...page}
        number={i + 1}
        index={i}
        pageIndex={i}
        deletePage={deletePage.bind(null, i)}
        reorderEnabled={reorderEnabled}
        disabled={!reorderEnabled}
      />
    ))}
  </div>
))

const Planche = SortableElement(({ url, number, deletePage, reorderEnabled }) => {
  const { history } = useRouter()
  return (
    <div className={cn("account-planche")}>
      <a
        target="blank"
        href={url}
        className={cn("img-container", { "animate-jiggle": reorderEnabled })}
        onClick={(e) => {
          e.preventDefault()
          history.push({
            search: qs.stringify({
              ...qs.parse(location.search),
              quick_open: 1,
              quick_open_page: number,
            }),
          })
        }}
      >
        <img src={url} alt={t(`account-page.planches-page.planche-img-alt`, { number })} />
      </a>
      <span className="delete-button-container">
        <span
          className="icon-cross"
          onClick={(_) => {
            deletePage()
          }}
        ></span>
      </span>
    </div>
  )
})

const withDeletePage = withMutation(
  gql`
    mutation DeletePage($participationId: ID!, $pageIndex: Int!) {
      deletePage(participationId: $participationId, pageIndex: $pageIndex) {
        id
        pagesDone
        achievement
        pages {
          url
          width
          height
        }
      }
    }
  `,
  (mutate, { participationId }) => ({
    deletePage: (pageIndex) =>
      mutate({
        variables: {
          participationId,
          pageIndex,
        },
      }).then((_) => {
        toast.success(t(`account-page.planches-page.planche-delete-done`))
      }),
  })
)

const withMovePage = withMutation(
  gql`
    mutation UpdatePagesOrder($participationId: ID!, $oldPageIndex: Int!, $newPageIndex: Int!) {
      updatePagesOrder(
        participationId: $participationId
        oldPageIndex: $oldPageIndex
        newPageIndex: $newPageIndex
      ) {
        id
        achievement
        pages {
          url
          width
          height
        }
      }
    }
  `,
  (mutate, { participationId, pages }) => ({
    movePage: (oldPageIndex, newPageIndex) =>
      mutate({
        variables: {
          participationId,
          oldPageIndex,
          newPageIndex,
        },
        optimisticResponse: {
          __typename: "Mutation",
          updatePagesOrder: {
            id: participationId,
            __typename: "Participation",
            pages: (function (pages) {
              let updatedPages = [...pages]
              updatedPages.splice(newPageIndex, 0, updatedPages.splice(oldPageIndex, 1)[0])
              return updatedPages
            })(pages),
          },
        },
      }).then((_) => {
        toast.success(t(`account-page.planches-page.reorder-mode.done`))
      }),
  })
)

const withPages = withQueryResult(
  gql`
    query Pages($participationId: ID!) {
      participation(id: $participationId) {
        id
        pagesDone
        pagesGoal
        achievement
        title
        challengeType
        hasValidatedInitialChallengeType
        tags
        user {
          id
          slug
        }
        pages {
          url
          width
          height
        }
      }
    }
  `,
  {
    variables: ({ participationId }) => ({ participationId }),
    props: ({ participation }) => ({
      participation,
      pages: participation.pages, // shortcut prop, deal with it
    }),
  }
)

const withEditionInfo = withQueryResult(
  gql`
    query EditionInfo($editionId: ID!) {
      edition(id: $editionId) {
        id
        beginDate
        endDate
        year
        status {
          usersCanUpload
        }
        useTitles
      }
    }
  `,
  { variables: ({ editionId }) => ({ editionId }) }
)

const withRelevantEditionIdAndParticipationId = (ChildComponent) => (props) => (
  <RelevantEditionContext.Consumer>
    {({ relevantEdition }) => (
      <ChildComponent
        editionId={relevantEdition && relevantEdition.id}
        participationId={
          relevantEdition && relevantEdition.myParticipation && relevantEdition.myParticipation.id
        }
        {...props}
      />
    )}
  </RelevantEditionContext.Consumer>
)

const enhance = compose(
  withRelevantEditionIdAndParticipationId,
  withEditionInfo,
  withPages,
  withMovePage,
  withDeletePage
)

export default enhance(Planches)
