import React, { useState, useEffect, useCallback } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import zip from "lodash/zip"
import Lightbox from "react-images"
import styled from "styled-components"
import Gallery from "react-photo-gallery"
import Markdown from "markdown-to-jsx"
import shuffle from "lodash/shuffle"

function s3ToCloudfrontUrl(url) {
  if (process.env.CLOUDFRONT_DOMAIN) {
    return url.replace(
      `http://${process.env.S3_BUCKET}.s3.amazonaws.com/`,
      `https://${process.env.CLOUDFRONT_DOMAIN}/`
    )
  }
  return url
}

function useResolvedFanarts(pageMdContent) {
  const imgRegex = /!\[[^\]]*\]\([^)]*\)/
  const imageUrls = [
    ...(pageMdContent.match(new RegExp(imgRegex.source, imgRegex.flags + "g")) || []),
  ].map((imgCode) => imgCode.match(/!\[[^\]]*\]\(([^)]*)\)/)[1])
  const [edito, ...contents] = pageMdContent.split(imgRegex)

  const fanartsWithoutDimensions = zip(imageUrls, contents).map(([src, content], i) => ({
    src: s3ToCloudfrontUrl(src),
    content: content.replace(/\s+\[\([vV]id[ée]o\)\]\(([^)]+)\)/, ""),
    video: content.match(/\[\([vV]id[ée]o\)\]\(([^)]+)\)/)
      ? content.match(/\[\([vV]id[ée]o\)\]\(([^)]+)\)/)[1]
      : null,
  }))
  const [fanarts, setFanarts] = useState(null)
  useEffect(() => {
    Promise.all(
      fanartsWithoutDimensions.map(
        (fanartWithoutDimensions) =>
          new Promise((resolve) => {
            const img = new Image()
            img.src = fanartWithoutDimensions.src
            img.onload = () => {
              resolve({
                ...fanartWithoutDimensions,
                width: img.naturalWidth,
                height: img.naturalHeight,
              })
            }
          })
      )
    )
      .then(shuffle)
      .then(setFanarts)
  }, [])
  return { edito, fanarts }
}

export default function FanartsContent({ children }) {
  const [openVideoIndex, setOpenVideoIndex] = useState(-1)
  const [lightboxIndex, setLightboxIndex] = useState(-1)

  useEffect(() => {
    setLightboxIndex(-1)
    setOpenVideoIndex(-1)
  }, [children])

  const { edito, fanarts } = useResolvedFanarts(children)

  const renderFanart = useCallback(({ index, left, top, key, photo }) => {
    return (
      <div
        key={photo.src}
        style={{
          position: "absolute",
          width: photo.width,
          height: photo.height,
          left,
          top,
          overflow: "hidden",
        }}
        className="fanart-image"
      >
        <img
          src={photo.src}
          width={photo.width}
          height={photo.height}
          onClick={() => {
            if (photo.video) {
              setOpenVideoIndex(index)
            } else {
              setLightboxIndex(index)
            }
          }}
          style={{ cursor: "pointer" }}
          tabIndex={-1}
        />
        <div
          className="fanart-info"
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0.5)",
            bottom: 0,
            left: 0,
            right: 0,
            color: "white",
            padding: "0.5rem 1rem",
          }}
        >
          <FanartInfo>{photo.content}</FanartInfo>
        </div>
      </div>
    )
  }, [])

  return (
    <React.Fragment>
      <Edito>
        <Markdown>{edito}</Markdown>
      </Edito>
      {!!fanarts && (
        <>
          <Gallery photos={fanarts} direction="column" renderImage={renderFanart} />
          <Lightbox
            images={fanarts.map(({ src, content, video }) => ({
              src,
              caption: <FanartInfo>{content}</FanartInfo>,
            }))}
            isOpen={lightboxIndex !== -1}
            onClickPrev={() => {
              setLightboxIndex(lightboxIndex - 1)
            }}
            onClickNext={() => {
              setLightboxIndex(lightboxIndex + 1)
            }}
            currentImage={lightboxIndex !== -1 ? lightboxIndex : undefined}
            onClose={() => {
              setLightboxIndex(-1)
            }}
            backdropClosesModal={true}
            imageCountSeparator=" sur "
            width={1200}
          />
          <Modal
            isOpen={openVideoIndex !== -1}
            toggle={() => {
              setOpenVideoIndex(-1)
            }}
          >
            {openVideoIndex !== -1 ? (
              <>
                <ModalHeader
                  toggle={() => {
                    setOpenVideoIndex(-1)
                  }}
                  className="d-block d-sm-none"
                />
                <ModalBody>
                  <div>
                    <video
                      src={fanarts[openVideoIndex].video}
                      controls
                      autoPlay
                      style={{ maxWidth: "90vw" }}
                    />
                  </div>
                  <div className="mt-1">
                    <FanartInfo>{fanarts[openVideoIndex].content}</FanartInfo>
                  </div>
                </ModalBody>
              </>
            ) : undefined}
          </Modal>
        </>
      )}
    </React.Fragment>
  )
}

const Edito = styled.div`
  font-size: 1rem;
  h1 {
    margin-bottom: 30px;
  }
`

function FanartInfo({ children }) {
  return (
    <Markdown
      options={{
        overrides: {
          p: { props: { className: "mb-0" } },
          a: { props: { target: "blank" } },
        },
      }}
    >
      {children}
    </Markdown>
  )
}
