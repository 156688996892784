import React, { useEffect, useMemo, useRef, useState } from "react"
import { createPortal } from "react-dom"
import Lightbox from "react-images"
import { Container } from "reactstrap"
import useReadStatuses from "utils/useReadStatuses"
import NSFWDisclaimer from "../NSFWDisclaimer"
import { useIsNSFWHidden } from "../useIsNSFWHidden"
import PreloadTurbomediaIfNeeded from "./PreloadTurbomediaIfNeeded"
import { MaybePromptForComment, useJustArrivedOnLastPage } from "./CommentPrompt"
import { debounce } from "lodash"
import { useMediaQuery } from "react-responsive"

function LightboxViewer({ participation, currentPage, setCurrentPage, onClose, headContent }) {
  const currentPageNumber = currentPage
  const currentPageIndex = currentPageNumber ? currentPageNumber - 1 : undefined
  const { readPage } = useReadStatuses()
  useEffect(() => {
    readPage(participation.id, currentPageNumber)
  }, [currentPageNumber])
  const isNSFWHidden = useIsNSFWHidden(participation)
  const pagesToShow = useMemo(
    () =>
      participation.pages.map(({ url }) => ({ src: url }))
      |> ((pages) => (isNSFWHidden ? pages.slice(0, 1) : pages)),
    [participation.pages]
  )
  const justArrivedLastPage = useJustArrivedOnLastPage({ currentPageIndex, participation })
  return (
    <PreloadTurbomediaIfNeeded isOpen participation={participation}>
      {justArrivedLastPage && (
        <PromptContainer>
          <FinishedText />
          <div style={{ height: 200 }}>
            <MaybePromptForComment participation={participation} />
          </div>
        </PromptContainer>
      )}
      <Lightbox
        images={pagesToShow}
        isOpen
        onClickPrev={() => {
          setCurrentPage(currentPageNumber - 1)
        }}
        onClickNext={() => {
          setCurrentPage(currentPageNumber + 1)
        }}
        currentImage={currentPageIndex}
        onClose={onClose}
        backdropClosesModal={true}
        imageCountSeparator={` ${t(`participation-page.planches.lightbox-image-count-separator`)} `}
        width={1200}
        showImageCount={!isNSFWHidden}
      />
      {headContent && (
        <HeadContentContainer key={currentPageIndex}>{headContent}</HeadContentContainer>
      )}

      {isNSFWHidden &&
        createPortal(
          <div style={{ position: "fixed", top: 20, left: 0, right: 0, zIndex: 2002 }}>
            <Container>
              <NSFWDisclaimer />
            </Container>
          </div>,
          document.body
        )}
    </PreloadTurbomediaIfNeeded>
  )
}

export function FinishedText() {
  return (
    <div
      className="animate-fade-in-up animate-fade-in-up-keep mb-3"
      style={{ animationDelay: "0.2s" }}
    >
      {t(`participation-page.comments-prompt.finished`)}
    </div>
  )
}

function PromptContainer({ children }) {
  useReRenderOnWindowResize()
  const { right, top, height } = document
    .querySelector("#lightboxBackdrop figure")
    .getBoundingClientRect()
  const hasRoomForPrompt = useMediaQuery({ query: `(min-width: 1580px)` })
  if (!hasRoomForPrompt) return null
  return createPortal(
    <div
      className="d-flex flex-column justify-content-center"
      style={{
        position: "fixed",
        zIndex: 2002,
        top,
        left: right,
        right: 0,
        height,
      }}
    >
      <div
        className="d-flex flex-column justify-content-center"
        style={{
          padding: 40,
          width: "min(100%, 500px)",
          color: "white",
        }}
      >
        {children}
      </div>
    </div>,
    document.body
  )
}

function useReRenderOnWindowResize() {
  const [, updateState] = useState()
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      updateState({})
    }, 50)
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  })
}

function HeadContentContainer({ children }) {
  const imageNode = useAwaitedLightboxImage()
  if (!imageNode) return null
  const { left, top, width } = imageNode.getBoundingClientRect()
  return createPortal(
    <div
      style={{
        position: "fixed",
        zIndex: 2002,
        bottom: window.innerHeight - top,
        left,
        width: width - 100,
        color: "white",
      }}
      className="pb-2"
    >
      {children}
    </div>,
    document.body
  )
}

function useAwaitedLightboxImage() {
  const [node, setNode] = useState(null)
  const initialNode = document.querySelector("#lightboxBackdrop figure img")
  const countRef = useRef(0)
  useEffect(() => {
    let interval
    if (!initialNode) {
      interval = setInterval(() => {
        if (countRef.current++ > 60) {
          // 3 seconds
          clearInterval(interval)
          return
        }
        const nodeNow = document.querySelector("#lightboxBackdrop figure img")
        if (nodeNow && nodeNow.getBoundingClientRect().width > 0) {
          setNode(nodeNow)
          clearInterval(interval)
        }
      }, 50)
    }
    return () => {
      clearInterval(interval)
    }
  }, [])
  return node || initialNode
}

export default LightboxViewer
