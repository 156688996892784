import React, { useEffect, useRef, useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import useMe from "utils/useMe"
import { Input, Button } from "reactstrap"
import cn from "classnames"
import { withAddUserComment } from "../AddCommentForm"
import Reactions from "../Reactions"

function CommentPrompt({ participationId, addUserComment, allowReactions }) {
  const [content, setContent] = useState("")
  const [enterToSend, setEnterToSend] = useState(true)
  const inputRef = useRef()
  const [sent, setSent] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    await addUserComment(content)
    setSent(true)
  }

  return (
    <div className="animate-fade-in-up">
      {allowReactions && (
        <div className="comments-prompt-reactions">
          <Reactions participationId={participationId} />
        </div>
      )}
      {sent ? (
        <div className="animate-fade-in-up">
          {t(`participation-page.comments-prompt.comment-sent`)}
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="animate-fade-in-up animate-fade-in-up-keep"
          style={{ animationDelay: allowReactions ? "0.4s" : undefined }}
        >
          <div>
            <Input
              type="textarea"
              placeholder={t(`participation-page.comments-prompt.comment-prompt`)}
              className="unstyled-focus"
              autoFocus={!inputRef.current}
              value={content}
              onChange={(e) => {
                setContent(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === "ArrowLeft" || e.key === "ArrowRight") e.stopPropagation()
                if (e.key === "Enter") {
                  if (enterToSend && !e.shiftKey) {
                    e.target.form.dispatchEvent(
                      new Event("submit", { bubbles: true, cancelable: true })
                    )
                  }
                }
              }}
              rows={content.split("\n").length}
              innerRef={inputRef}
            />
          </div>
          <div className={cn("d-flex mt-3")}>
            <div
              className={cn("flex-grow-1", { "hidden-in-flow": content === "" })}
              style={{ color: "#777" }}
            >
              <Input
                type="checkbox"
                className="checkbox-in-flow mr-2"
                checked={enterToSend}
                onChange={(e) => {
                  setEnterToSend(e.target.checked)
                  setTimeout(() => {
                    if (inputRef.current) {
                      inputRef.current.setSelectionRange(
                        inputRef.current.value.length,
                        inputRef.current.value.length
                      )
                      inputRef.current.focus()
                    }
                  }, 100)
                }}
              />
              {t(`participation-page.comments-prompt.enter-to-send`)}
            </div>
            <Button type="submit" color="primary">
              {t(`global.send`)}
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}

const EnhancedCommentPrompt = withAddUserComment(CommentPrompt)

const ParticipationCommentsUsers = gql`
  query ParticipationCommentsUsers($participationId: ID!) {
    participation(id: $participationId) {
      id
      allowComments
      allowReactions
      comments {
        id
        ... on UserComment {
          author {
            id
          }
        }
      }
    }
  }
`

export function MaybePromptForComment({ participation, delay = 600 }) {
  const me = useMe()
  const { data } = useQuery(ParticipationCommentsUsers, {
    skip: !me,
    variables: { participationId: participation.id },
  })
  const [hasWaited, setHasWaited] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setHasWaited(true)
    }, delay)
  }, [])
  const [gqlResponse, setGqlResponse] = useState(null)
  useEffect(() => {
    // doin that then the EnhancedCommentPrompt doesn't disapear once we sent a comment
    if (gqlResponse === null && data && data.participation && !!me) {
      setGqlResponse(
        data.participation.allowComments &&
          !data.participation.comments.some(
            (comment) => comment.__typename === "UserComment" && comment.author.id === me.id
          )
      )
    }
  }, [data && data.participation])
  if (hasWaited && !!me && gqlResponse) {
    return (
      <EnhancedCommentPrompt
        participationId={participation.id}
        allowReactions={data.participation.allowReactions}
      />
    )
  }
  return null
}

export function useJustArrivedOnLastPage({ participation, currentPageIndex }) {
  const previousPageIndexRef = useRef(null)
  const [justArrivedLastPage, setJustArrivedLastPage] = useState(false)
  useEffect(() => {
    if (
      currentPageIndex === participation.pagesDone - 1 &&
      previousPageIndexRef.current !== null &&
      previousPageIndexRef.current < currentPageIndex
    ) {
      setJustArrivedLastPage(true)
    }
    if (currentPageIndex !== participation.pagesDone - 1) {
      setJustArrivedLastPage(false)
    }
    previousPageIndexRef.current = currentPageIndex
  }, [currentPageIndex])
  return justArrivedLastPage
}
