export const ALLOWED_FILE_TYPES = ["image/jpeg"]

export const MAXIMUM_FILE_SIZE = 200 * 1024 // 200 Mo

export const MAXIMUM_DIMENSIONS = {
  width: 135,
  height: 135,
}

export const INSTRUCTIONS_KEY = `account-page.profile-page.avatar.modal.instructions-20200322`
