import React from "react"
import { Row, Col, Form, Button } from "reactstrap"
import gql from "graphql-tag"
import cn from "classnames"
import { reduxForm, Field, reset, formValueSelector } from "redux-form"
import compose from "utils/compose"
import { toast } from "react-toastify"
import { connect } from "react-redux"
import { useMutation } from "@apollo/react-hooks"

function AddCommentForm({ className, style, handleSubmit, canSubmit }) {
  return (
    <Form className={cn("px-sm-5", className)} style={style} onSubmit={handleSubmit}>
      <div>
        <Row>
          <Col>
            <Field component="textarea" name="content" className="form-control mt-3" rows={4} />
          </Col>
        </Row>
        <div className="text-center mt-3">
          <Button color="hbd" type="submit" disabled={!canSubmit}>
            {t(`blog-post-page.comments.add-comment.submit`)}
          </Button>
        </div>
      </div>
    </Form>
  )
}

const withUserCanSubmit = connect((state, { form }) => ({
  canSubmit: !!formValueSelector(form)(state, "content"),
}))

const withForm = reduxForm({
  onSubmit: ({ content }, dispatch, { addComment, form }) => {
    addComment(content).then(() => {
      toast.success(t(`blog-post-page.comments.add-comment.done`))
      dispatch(reset(form))
    })
  },
})

const withAddComment = (ChildComponent) => (props) => {
  const { blogPostId } = props
  const [mutate] = useMutation(gql`
    mutation AddBlogPostComment($blogPostId: ID!, $input: BlogPostCommentInput!) {
      addBlogPostComment(blogPostId: $blogPostId, input: $input) {
        id
        author {
          id
          username
          avatarUrl
        }
        content
        date
      }
    }
  `)
  const addComment = (content) => {
    return mutate({
      variables: {
        blogPostId,
        input: {
          content,
        },
      },
      refetchQueries: ["BlogPost"],
    })
  }

  return <ChildComponent {...props} addComment={addComment} form="blogPostComment" />
}

const userEnhance = compose(withAddComment, withForm, withUserCanSubmit)
export default userEnhance(AddCommentForm)
