import React from "react"
import { useMutation } from "@apollo/react-hooks"
import cn from "classnames"
import { policies } from "common/flavorConfig.hbd"
import gql from "graphql-tag"
import { useState } from "react"
import { Button, ButtonGroup } from "reactstrap"
import t from "utils/t"
import { toast } from "react-toastify"

export default function ChallengeTypeForm({
  participationId,
  initialChallengeType,
  initialChoice = false,
  onClose,
}) {
  const [challengeType, setChallengeType] = useState(initialChallengeType)
  const [updateChallengeType] = useMutation(gql`
    mutation UpdateChallengeType(
      $participationId: ID!
      $challengeType: ChallengeType!
      $hasValidatedInitialChallengeType: Boolean
    ) {
      updateParticipation(
        participationId: $participationId
        challengeType: $challengeType
        hasValidatedInitialChallengeType: $hasValidatedInitialChallengeType
      ) {
        id
        challengeType
        hasValidatedInitialChallengeType
      }
    }
  `)
  const onSubmit = async (e) => {
    e.preventDefault()
    if (challengeType !== initialChallengeType || initialChoice) {
      await updateChallengeType({
        variables: {
          participationId,
          challengeType: challengeType,
          hasValidatedInitialChallengeType: true,
        },
      })
    }
    if (challengeType !== initialChallengeType) {
      toast.success(t(`account-page.planches-page.challenge-type.done`))
    }
    onClose()
  }
  return (
    <form
      className="mt-3 mb-5 text-center d-flex flex-column justify-content-center align-items-center"
      onSubmit={onSubmit}
    >
      <div className="mb-3" style={{ fontSize: "1.2em" }}>
        {initialChoice
          ? t(`account-page.planches-page.challenge-type.initial-choice.my-challenge-type`)
          : t(`account-page.planches-page.challenge-type.my-challenge-type`)}
      </div>
      <ButtonGroup>
        {policies.challengeTypes.map((itemChallengeType, i) => (
          <Button
            color={challengeType === itemChallengeType ? "primary" : "light"}
            className={cn("d-block text-center py-2 pb-3 px-5", { "ml-1": i > 0 })}
            key={itemChallengeType}
            onClick={(e) => {
              setChallengeType(itemChallengeType)
            }}
          >
            <div className="text-uppercase font-weight-bold mb-1" style={{ fontSize: "1.2em" }}>
              {t(`global.challenge-type.${itemChallengeType}`)}
            </div>
            <div style={{ lineHeight: 1 }}>
              <div className="text-lowercase" style={{ fontSize: "0.7em" }}>
                {t(`account-page.planches-page.challenge-type.goal`)}
              </div>
              <div style={{ fontSize: "2em" }} className="font-weight-bold">
                {policies.pagesGoalPerChallengeType[itemChallengeType]}
              </div>
              <div style={{ fontSize: "0.7em" }}>{t(`global.page-term.${itemChallengeType}`)}</div>
            </div>
          </Button>
        ))}
      </ButtonGroup>
      <div className="mt-4">
        {!initialChoice && (
          <Button
            color="light"
            className="mr-2"
            onClick={() => {
              onClose()
            }}
          >
            {t(`account-page.planches-page.challenge-type.cancel`)}
          </Button>
        )}
        <Button color="primary" type="submit">
          {t(`account-page.planches-page.challenge-type.submit`)}
        </Button>
      </div>
      {initialChoice && (
        <div className="mt-2" style={{ fontSize: "0.9em" }}>
          {t(`account-page.planches-page.challenge-type.initial-choice.can-change-later`)}
        </div>
      )}
    </form>
  )
}
