import React from "react"
import cn from "classnames"
import clapEmote from "assets/images/emotes/clap.png"
import heartEyesEmote from "assets/images/emotes/heart_eyes.png"
import joyEmote from "assets/images/emotes/joy.png"
import screamEmote from "assets/images/emotes/scream.png"
import cryEmote from "assets/images/emotes/cry.png"

const EMOTE_IMGS = {
  clap: clapEmote,
  heart_eyes: heartEyesEmote,
  joy: joyEmote,
  scream: screamEmote,
  cry: cryEmote,
}


const ReactionBadge = ({ checked, disabled, onClick, emote, size = 24, count, ...props }) => {
  if (disabled) onClick = undefined

  return (
    <span
      {...props}
      onClick={onClick}
      className={cn(
        "reaction-badge",
        {
          disabled,
          checked,
          empty: !count,
        },
        props.className
      )}
    >
      <span
        className="reaction-emote"
        style={{ "--emote-img": `url("${EMOTE_IMGS[emote]}")`, "--emote-size": `${size}px` }}
      />
      <span className="count">{count || (disabled ? "" : "+")}</span>
    </span>
  )
}

export default ReactionBadge
