import React, { useMemo, useRef } from "react"
import { Link } from "react-router-dom"
import useBreakpoint from "utils/useBreakpoint"
import { Trans } from "react-i18next"
import moment from "moment"
import nl2br from "utils/nl2br"
import isIn from "common/fp/isIn"
import cn from "classnames"
import * as styles from "./index.module.hbd.scss"
import { style } from "react-toastify"
// import Markdown from 'utils/Markdown'
import Markdown from "markdown-to-jsx"
import { createPortal } from "react-dom"
import { Query } from "@apollo/react-components"
import gql from "graphql-tag"
import { Featured } from "./Featured"
import { RandomRead } from "./RandomRead"
import userPlaceHolderImage from "assets/images/user-placeholder.hbd.jpg"
import isaarneThumb from "assets/images/23hbd/2025/isaarne.png"
import petitsDebrouillardsThumb from "assets/images/23hbd/2025/petits-debrouillards.png"

const now = moment()

function PortaledH1({ target, children }) {
  return createPortal(children, target)
}

function Home({ currentEdition }) {
  const titleRef = useRef()
  const period = useMemo(() => {
    const beginDate = moment(currentEdition.beginDate)
    const endDate = moment(currentEdition.endDate)
    if (now.isBefore(beginDate.clone().subtract(8, "hours"))) return "before"
    if (now.isBefore(beginDate)) return "day-before"
    if (now.isBefore(endDate)) return "during"
    if (now.isBefore(endDate.clone().add(11, "hours"))) return "day-after"
    return "after"
  }, [currentEdition])
  const randomReadYear =
    period === "day-after" || period === "after" ? currentEdition.year : currentEdition.year - 1
  return (
    <div>
      <div className={cn("d-flex flex-column flex-lg-row", styles.mainWrapper)}>
        <div className={styles.mainContent}>
          <h1 ref={titleRef} className="text-secondary" />
          <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
            <div>
              <div className={styles.mascotte} />
            </div>
            <div className={cn("flex-grow-1 py-3 px-sm-4")}>
              {!!titleRef.current && (
                <Markdown
                  options={{
                    overrides: {
                      h1: { component: PortaledH1, props: { target: titleRef.current } },
                    },
                  }}
                >
                  {t(`flavored:home-page.content`)}
                </Markdown>
              )}
              {period === "before" && (
                <div className={style.editionDatesInfo}>
                  {t(`flavored:home-page.before-content.dates-info`, {
                    startDay: moment(currentEdition.beginDate).date(),
                    endDay: moment(currentEdition.endDate).date(),
                    year: moment(currentEdition.beginDate).year(),
                  })}
                </div>
              )}
              {period === "day-before" && (
                <div className={style.editionDatesInfo}>
                  {t(`flavored:home-page.day-before-content.dates-info`)}
                </div>
              )}
              {(period |> isIn(["during", "day-after", "after"])) && (
                <div className={styles.themeInfo}>
                  {currentEdition.theme ? (
                    <Markdown>{currentEdition.theme}</Markdown>
                  ) : (
                    t(`flavored:home-page.during-content.theme-incoming`)
                  )}
                </div>
              )}
              <TwitchBlock currentEdition={currentEdition} />
            </div>
          </div>
        </div>
        <aside
          className="my-3 text-center border-left-lg-0 px-lg-3 d-flex flex-lg-column align-items-center pb-5 pb-lg-0 justify-content-around justify-content-lg-start"
          style={{ gap: "20px" }}
        >
          <Vip
            src={isaarneThumb}
            links={[
              {
                label: t(`global.read-interview`),
                to: "/blog/661012_isabelle-arne-illustratrice-2025/",
              },
              {
                label: t(`global.download-edition-poster`),
                href: `https://d3npuczmg449ex.cloudfront.net/content/Affiche+23hBD+2025.png`,
              },
            ]}
            style={{ maxWidth: 190 }}
          >
            {t(`flavored:home-page.vip-2025.illustrator`)}
          </Vip>
          <Vip
            src={petitsDebrouillardsThumb}
            links={[
              {
                label: t(`global.read-interview`),
                to: "/blog/385672_les-petits-debrouillards-partenaire-2025/",
              },
            ]}
            style={{ maxWidth: 190 }}
          >
            {t(`flavored:home-page.vip-2025.partner`)}
          </Vip>
        </aside>
      </div>
      <RandomRead year={randomReadYear} />
    </div>
  )
}

function TwitchBlock({ currentEdition }) {
  const isLg = useBreakpoint("lg")
  if (
    currentEdition &&
    currentEdition.customData &&
    currentEdition.customData.twitch &&
    currentEdition.customData.twitch.active
  ) {
    return isLg ? (
      <div className="mt-5">
        <div
          className="d-flex justify-content-center mt-5"
          dangerouslySetInnerHTML={{ __html: currentEdition.customData.twitch.content }}
        />
      </div>
    ) : (
      <div className="mt-5">
        <Trans i18nKey="flavored:home-page.radio-carotte.simple-link">
          Cette année, suivez les 23hBD avec le stream officiel,{" "}
          <a href={currentEdition.customData.twitch.url}>Radio Carotte</a> !
        </Trans>
      </div>
    )
  }
  return null
}

function Vip({ src, to, href, children, links, placeholder, imgStyle, className, style }) {
  return pug`
    .vip(className=className, style=style)
      AnyLink(to=to)
        .vip-thumbnail
          img(src=(src || userPlaceHolderImage) alt=children style=imgStyle)
      .mt-2
        div
          AnyLink.link-unstyled(to=to href=href)
            span.vip-name= children
        .vip-links
          each link, index in links
            AnyLink(key=index to=link.to href=link.href target=link.target)= link.label
  `
}

function AnyLink(props) {
  let ComponentToUse = "span"
  if (typeof props.href === "string") {
    ComponentToUse = "a"
  }
  if (typeof props.to === "string") {
    ComponentToUse = Link
  }
  return <ComponentToUse {...props} />
}

export default Home
