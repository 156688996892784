import React from "react"
import cn from "classnames"
import { NavLink } from "react-router-dom"
import useMe from "utils/useMe"
import { Trans } from "react-i18next"

export default function NSFWDisclaimer({ style, className, ...props }) {
  const me = useMe()
  return (
    <div
      className={cn("post-it-info mx-3 mx-md-0", className)}
      style={{ backgroundColor: "#ffc487", color: "black", ...style }}
      {...props}
    >
      {me ? (
        t(`participation-page.nsfw-disclaimer.connected-but-underage`)
      ) : (
        <Trans i18nKey="participation-page.nsfw-disclaimer.not-connected">
          Contenu 18+. Pour lire la suite,
          <NavLink to="/connexion/" className="link-unstyled link-underlined">
            connecte-toi
          </NavLink>
        </Trans>
      )}
    </div>
  )
}
