import webcamImage from "assets/images/cam.svg"
import cn from "classnames"
import Achievement from "components/Achievement"
import Checkbox from "components/Checkbox"
import React, { useCallback, useContext } from "react"
import { Link } from "react-router-dom"
import useBreakpoint from "utils/useBreakpoint"
import { ItemsContext } from "."
import { SearchResultsFromOtherEditionsContext } from "./SearchInOtherEditions"
import TwitchLightIcon from "-!react-svg-loader!assets/images/twitch-light.svg"
import StreamIcon from "-!react-svg-loader!assets/images/stream.svg"
import { getTwitchChannel } from "common/getTwitchChannel"

export default function ParticipationsList({ children, className, bordered }) {
  const sm = useBreakpoint("sm")
  const lg = useBreakpoint("lg")
  const numberOfColumns = lg ? 3 : sm ? 2 : 1
  const colSize = Math.ceil(children.length / numberOfColumns)
  const columns = []
  for (let col = 0; col < numberOfColumns; col++) {
    columns.push(children.slice(col * colSize, (col + 1) * colSize))
  }
  return (
    <div className={cn(className, "columns-layout d-flex", { bordered })}>
      {columns.map((colChildren, colIndex) => (
        <div key={colIndex} className="columns-layout-col">
          {colChildren}
        </div>
      ))}
    </div>
  )
}

ParticipationsList.Item = React.memo(
  ({
    participation: {
      id,
      user: { id: userId, username, webcamUrl, slug },
      specificUsername,
      pagesDone,
      pagesGoal,
      achievement,
      edition,
    },
    className,
    readCompletion,
    followed,
    setFollowed,
    showReadStatuses,
  }) => {
    const {
      shouldShowWebcam,
      useTitles,
      quickOpen,
      focusUser,
      editFollowed,
      emptyIsMainstream,
      twitchStreamsCache,
    } = useContext(ItemsContext)
    const fromOtherEdition = !!useContext(SearchResultsFromOtherEditionsContext)
    const onMouseEnter = useCallback(
      (e) => {
        focusUser(userId)
      },
      [focusUser]
    )
    return (
      <div
        className={cn(
          "hbd-participant",
          className,
          {
            "read-status": showReadStatuses && !editFollowed,
            "empty-is-mainstream": emptyIsMainstream,
          },
          showReadStatuses && !editFollowed && readCompletion
        )}
      >
        {editFollowed && (
          <Checkbox
            checked={followed}
            onClick={() => {
              setFollowed(!followed)
            }}
            className="mr-1"
          />
        )}

        <Link
          className="hbd-participant__pseudo link-unstyled"
          to={`/participants/${edition.year}/${slug}/`}
          onMouseEnter={onMouseEnter}
          onClick={
            editFollowed
              ? (e) => {
                  e.preventDefault()
                  setFollowed(!followed)
                }
              : undefined
          }
        >
          {specificUsername || username}
          {fromOtherEdition && (
            <span
              style={{ fontSize: "0.8em" }}
              className="text-muted  "
            >{` (${edition.year})`}</span>
          )}
        </Link>

        {webcamUrl && shouldShowWebcam ? (
          <a
            className="ml-2 d-inline-flex link-unstyled stream-link"
            href={webcamUrl |> fixWebcamUrlIfNeeded}
            target="blank"
          >
            <StreamIndicator twitchStreamsCache={twitchStreamsCache}>{webcamUrl}</StreamIndicator>
          </a>
        ) : undefined}
        <Link
          className="hbd-participant__pages link-unstyled"
          to={`/participants/${edition.year}/${slug}/`}
          onMouseEnter={onMouseEnter}
        >
          <Achievement fillSpace className="mr-2">
            {achievement}
          </Achievement>
          {pagesDone}/{pagesGoal}
        </Link>
      </div>
    )
  }
)

export function StreamIndicator({ children: streamUrl, twitchStreamsCache, className }) {
  if (streamUrl.includes("twitch.tv")) {
    const channel = getTwitchChannel(streamUrl)
    const active =
      !!channel &&
      !!twitchStreamsCache &&
      !!twitchStreamsCache.updatedAt &&
      !!twitchStreamsCache.liveChannels[channel]
    return (
      <TwitchLightIcon
        height="1em"
        className={cn("stream-link-twitch", { "stream-link-active": active }, className)}
      />
    )
  }
  return <StreamIcon height="1.35em" className={cn("stream-link-other", className)} />
}

export function fixWebcamUrlIfNeeded(webcamUrl) {
  const channel = getTwitchChannel(webcamUrl)
  if (channel) return `https://www.twitch.tv/${channel}`
  return webcamUrl
}
