import React from "react"

export default ({ style }) => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 125" style={style}>
    <g>
      <path d="M100.392,70.668V4.027c0-2.461-1.996-4.458-4.458-4.458H4.065c-2.461,0-4.457,1.996-4.457,4.458V70.71   c0,2.462,1.996,4.458,4.457,4.458H76.41v20.805c0,2.005,1.34,3.764,3.273,4.298c0.395,0.107,0.791,0.16,1.185,0.16   c1.54,0,3.01-0.803,3.827-2.176l15.04-25.216c0.087-0.144,0.166-0.291,0.238-0.443c0.003-0.006,0.006-0.014,0.009-0.02   c0.027-0.059,0.053-0.119,0.078-0.18c0.005-0.014,0.011-0.027,0.016-0.041c0.021-0.053,0.042-0.106,0.06-0.159   c0.01-0.021,0.016-0.044,0.023-0.065c0.015-0.045,0.03-0.092,0.043-0.139c0.01-0.028,0.019-0.059,0.026-0.089   c0.011-0.038,0.021-0.075,0.031-0.114c0.009-0.039,0.019-0.077,0.025-0.116c0.008-0.03,0.014-0.061,0.02-0.093   c0.011-0.046,0.019-0.095,0.026-0.142c0.004-0.023,0.007-0.047,0.012-0.069c0.008-0.056,0.016-0.111,0.021-0.167   c0.001-0.017,0.003-0.032,0.004-0.049c0.007-0.063,0.013-0.126,0.016-0.189c0-0.011,0.001-0.02,0.001-0.03   c0.005-0.069,0.005-0.138,0.007-0.207c0-0.006,0-0.011,0-0.017v-0.009C100.392,70.69,100.392,70.679,100.392,70.668z    M80.868,66.254L80.868,66.254L80.868,66.254H8.522V8.484h82.956v60.998l-6.153,10.314V70.71   C85.324,68.248,83.33,66.254,80.868,66.254z" />
      <path d="M78.562,32.912H21.437c-2.462,0-4.457,1.996-4.457,4.458s1.995,4.458,4.457,4.458h57.125c2.462,0,4.458-1.996,4.458-4.458   S81.023,32.912,78.562,32.912z" />
      <path d="M78.562,17.348H21.437c-2.462,0-4.457,1.996-4.457,4.457c0,2.462,1.995,4.458,4.457,4.458h57.125   c2.462,0,4.458-1.996,4.458-4.458C83.02,19.343,81.023,17.348,78.562,17.348z" />
      <path d="M78.562,48.187H21.437c-2.462,0-4.457,1.996-4.457,4.458c0,2.46,1.995,4.456,4.457,4.456h57.125   c2.462,0,4.458-1.996,4.458-4.456C83.02,50.182,81.023,48.187,78.562,48.187z" />
    </g>
  </svg>
)
