export default [
  "home",
  "rules",
  "rules-en_GB",
  "rules-es_ES",
  "rules-it_IT",
  "rules-pt_PT",
  "rules-de_DE",
  "rules-ja_JP",
  "goodies",
]
