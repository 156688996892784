export const EMOTES = [
  "clap", // 👏
  "heart_eyes", // 😍
  "joy", // 😂
  "scream", // 😱
  "cry", // 😢
]

// 'fearful', // 😨
// 'laughing', // 😆
// 'smirk', // 😏
// 'thinking_face', // 🤔
// 'joy', // 😂
// 'sob', // 😭

export const MAX_REACTIONS_PER_USER = 3
