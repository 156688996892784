import React, { useEffect, useRef } from "react"
import Footer from "./Footer.hbd"
import Header from "./Header"

function Layout({ children }) {
  const containerRef = useHideUglyBgPart()
  return (
    <React.Fragment>
      <Header />
      <div className="container hbd-content-container main-main" ref={containerRef}>
        {children}
      </div>
      <Footer />
    </React.Fragment>
  )
}

// for 23hbd 2025
function useHideUglyBgPart() {
  const containerRef = useRef(null)

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        window.document.body.style.setProperty(
          "--hide-ugly-bg-part-offset",
          entry.contentRect.bottom < 750 ? "260px" : "0px"
        )
      }
    })

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [])
  return containerRef
}

export default Layout
