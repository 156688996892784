import React from "react"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import moment from "moment"
import { INSTRUCTIONS_KEY } from "common/plancheUploadPolicy"
import Markdown from "markdown-to-jsx"
import { Table, UncontrolledCollapse } from "reactstrap"
import sortBy from "lodash/fp/sortBy"
import Achievement from "components/Achievement"
import "./rules.23hbd.scss"

export default function Rules() {
  const {
    data: {
      currentEdition: { beginDate, endDate },
    },
  } = useQuery(
    gql`
      query CurrentEditionDates {
        currentEdition {
          id
          beginDate
          endDate
        }
      }
    `,
    { fetchPolicy: "cache-only" }
  )

  return (
    <Markdown
      className="rules"
      options={{
        overrides: {
          a: { component: AnchorOverride },
          ListOfPastThemesAndConstraints: { component: ListOfPastThemesAndConstraints },
          Achievement: { component: Achievement },
          UncontrolledCollapse: { component: UncontrolledCollapse },
        },
      }}
    >
      {t("flavored:rules-page.content", {
        beginDate: moment(beginDate),
        endDate: moment(endDate),
        planchesUploadInstructions: {
          fileType: {
            classico: t(`${INSTRUCTIONS_KEY}.file-type.classico`),
            turbomedia: t(`${INSTRUCTIONS_KEY}.file-type.turbomedia`),
            webtoon: t(`${INSTRUCTIONS_KEY}.file-type.webtoon`),
          },
          imageDimensions: {
            classico: t(`${INSTRUCTIONS_KEY}.image-dimensions.classico`),
            turbomedia: t(`${INSTRUCTIONS_KEY}.image-dimensions.turbomedia`),
            webtoon: t(`${INSTRUCTIONS_KEY}.image-dimensions.webtoon`),
          },
        },
      })}
    </Markdown>
  )
}

function AnchorOverride(props) {
  let className = props.className
  const children = Array.isArray(props.children) ? props.children[0] : props.children
  if (typeof children === "string" && children.match(/^\(.+\)$/)) {
    className = "small" // cn("small", className)
  }
  if (typeof props.href === "string" && props.href.match(/^#(.+)$/)) {
    const [match, targetId] = props.href.match(/^#(.+)$/)
    props = {
      ...props,
      onClick(e) {
        window.document.getElementById(targetId).click()
      },
    }
  }
  return <a {...props} className={className} />
}

function ListOfPastThemesAndConstraints() {
  const { data, loading, error } = useQuery(gql`
    query PastThemes {
      editions {
        id
        year
        theme
      }
    }
  `)
  if (!data || !data.editions) return null
  return (
    <Table size="sm">
      <thead>
        <tr>
          <th>{t(`global.year`)}</th>
          <th>{t(`global.theme`)}</th>
          <th>{t(`global.constraint`)}</th>
        </tr>
      </thead>
      <tbody>
        {(data.editions.filter(({ theme }) => !!theme) |> sortBy("year")).map(
          ({ id, year, theme: themeAndConstraintStr }) => (
            <tr key={id}>
              <td>{year}</td>
              <td>{separateThemeAndConstraint(themeAndConstraintStr).theme}</td>
              <td>{separateThemeAndConstraint(themeAndConstraintStr).constraint}</td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  )
}

function separateThemeAndConstraint(themeAndConstraintStr) {
  let [theme, constraint] = themeAndConstraintStr.split(
    /(?:Contrainte|Constraint|Restricción|Vincolo) ?:/
  )
  if (constraint) {
    constraint = constraint.trim()
  }
  theme = theme.replace(/(?:Thème|Theme|Tema|) ?:/, "").trim()
  return { theme, constraint }
}
