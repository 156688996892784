import React from "react"
import ArrowRightIcon from "-!react-svg-loader!assets/images/arrow-fat.svg"
import { useQuery } from "@apollo/react-hooks"
import cn from "classnames"
import gql from "graphql-tag"
import throttle from "lodash/throttle"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import useBreakpoint from "utils/useBreakpoint"
import Home from "./index.hbd"

export default () => {
  const { loading, error, preRegisterableEdition } = usePreRegister()
  const {
    data: { currentEdition },
  } = useQuery(
    gql`
      query CurrentEdition {
        currentEdition {
          id
          year
          beginDate
          endDate
          customData
          theme
        }
      }
    `,
    { fetchPolicy: "cache-only" }
  )
  const isMobile = !useBreakpoint("md")
  if (loading || error) return null
  return (
    <div>
      {preRegisterableEdition && (
        <div style={{ margin: "-24px -24px 0 -24px", paddingBottom: 24 }}>
          <div className="post-it-info preregister-info">
            L'édition {preRegisterableEdition.year} arrive ! Tu peux déjà t'inscrire{" "}
            <Link to="/register">ici</Link>
          </div>
        </div>
      )}
      <Home currentEdition={currentEdition} />
      {isMobile && <ReadComicsFloatingButtonForMobile />}
    </div>
  )
}

function ReadComicsFloatingButtonForMobile() {
  const [scrollY, setScrollY] = useState(window.scrollY)

  const {
    data: { currentEdition },
  } = useQuery(
    gql`
      query CurrentEditionYear {
        currentEdition {
          id
          year
        }
      }
    `,
    { fetchPolicy: "cache-only" }
  )
  const yearForList = currentEdition.year

  useEffect(() => {
    const handleScroll = throttle(() => {
      setScrollY(window.scrollY)
    }, 100)

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
      handleScroll.cancel()
    }
  }, [])
  const shouldShow = scrollY > 0
  return (
    <div
      className={cn("read-comics-floating-button", {
        "read-comics-floating-button-active": shouldShow,
      })}
    >
      <Link
        className="btn btn-primary btn-lg"
        to={`/participants/${yearForList}/?view_mode=gallery`}
      >
        {t(`layout.main-menu.gallery`, `Lire les BDs`)} <ArrowRightIcon height="1em" />
      </Link>
    </div>
  )
}

function usePreRegister() {
  const {
    data: { incomingEdition },
  } = useQuery(
    gql`
      query IncomingEdition {
        incomingEdition {
          id
          year
          status {
            usersCanRegister
          }
        }
      }
    `,
    { fetchPolicy: "cache-only" }
  )
  const skip = !(incomingEdition && incomingEdition.status.usersCanRegister)
  const { data, loading, error } = useQuery(
    gql`
      query MyParticipationToIncomingEdition($editionId: ID!) {
        me {
          id
          participation(editionId: $editionId) {
            id
          }
        }
      }
    `,
    {
      skip,
      variables: { editionId: incomingEdition && incomingEdition.id },
    }
  )

  return {
    loading,
    error,
    preRegisterableEdition: skip
      ? null
      : loading || error
        ? null
        : data.me && data.me.participation
          ? null
          : incomingEdition,
  }
}
